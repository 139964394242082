import React, { useState, useEffect } from "react";
import "./App.css";
import "./assets/css/demo.css";
import "./assets/css/light-bootstrap-dashboard.scss";
import "./assets/css/all.css";
import "./assets/css/all.min.css";
import "./assets/css/fontawesome.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/animate.css";

import { HashRouter } from "react-router-dom";
import { Route } from "react-router-dom";
import MainPage from "./Containers/MainPage/MainPage";
import Setting from "./Containers/Settings/Settings.jsx";
import Reports from "./Containers/Reports/Reports";

// import SignIU from "./SignIU/SignIU";
import Cookies from "universal-cookie";
import "./assets/css/swiper.min.css";
import ManageDevices from "./Containers/ManageDevices/ManageDevices.js";
import TableLayout from "./Components/Layouts/TableLayoutNew/TableLayout.jsx";
import Auth from "./Containers/Auth/Auth";
import Profile from "./Containers/Profile/Profile";
import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import Sidebar from "./Components/Sidebar/Sidebar";
import Nav from "./Components/Nav/Nav";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary.js";

function App(props) {
  const cookies = new Cookies();


  useEffect(() => {
    console.log('Inside the useEffect function');
  }, []);

  // this.props.onTryAutoSignup();

  // const [defaultState, updateState] = useState({
  //   themes: [
  //     {
  //       name: "Dark",
  //       effect: "",
  //       dataColor: cookies.get("themecolor"),
  //       // icon: <i className="fad fa-sun"></i>,
  //       textColor: "Black",
  //     },
  //   ],
  //   navigate_state: [
  //     {
  //       panel_1: "active",
  //       panel_2: "deactive",
  //       panel_3: "deactive",
  //       panel_4: "deactive",
  //     },
  //   ],
  //   toggleNav: "false",
  //   layout: "card"
  // });


  let Authentication = localStorage.getItem('token') === null;

  let theme = localStorage.getItem('theme-data');
  let accent = localStorage.getItem('accent-color');


  if (!theme) {
    localStorage.setItem('theme-data', "Light");
  }

  if (theme === "Light") {
    document.documentElement.setAttribute("theme-data", "Light");
  }
  else {
    document.documentElement.setAttribute("theme-data", "Dark");
  }

  if (accent) {
    document.documentElement.setAttribute("accent-color", accent);
  }






  console.log(localStorage.getItem('token'));
  console.log(Authentication);

  const Layouts = () => {
    if (cookies.get("layout") === "table") {
      return (
        <TableLayout toggleNavigation={toggleNavigation} />)
    }
    else {
      return (
        <MainPage
          dataColor={cookies.get("themename")}
          textColor={cookies.get("themetext")}
          toggleNavigation={toggleNavigation}
        >
        </MainPage>);

    }
  }

  // const cookiesDefault = () => {
  //   if (cookies.get("themename") === undefined) {
  //     cookies.set("themename", "Light", { path: "/" });
  //     cookies.set("themeeffect", "", { path: "/" });
  //     cookies.set("themecolor", "purple", { path: "/" });
  //     // cookies.set("themeicon", "<i className='fad fa-sun'></i>", { path: "/" });
  //     cookies.set("themetext", "Black", { path: "/" });
  //     cookies.set("panel1", "active", { path: "/" });
  //     cookies.set("panel2", "deactive", { path: "/" });
  //     cookies.set("panel3", "deactive", { path: "/" });
  //     cookies.set("panel4", "deactive", { path: "/" });
  //     cookies.set("temp_u", "deg_c", { path: "/" });
  //     cookies.set("press_u", "pa", { path: "/" });
  //     cookies.set("layout", "card", { path: "/" });
  //     // cookies.get("layout", "card", { path: "/" });
  //   }
  // };

  //  cookiesDefault();

  const sidebarcolorHandler = (val, id) => {
    // console.log(val, id);
    // alert(id)

    document.documentElement.setAttribute("theme-color", val)

    // document.getElementById("pa1").classList.remove("active");
    // document.getElementById("pa2").classList.remove("active");
    // document.getElementById("pa3").classList.remove("active");
    // document.getElementById("pa4").classList.remove("active");
    // document.getElementById("pa5").classList.remove("active");
    // document.getElementById("pa6").classList.remove("active");
    // document.getElementById("pa7").classList.remove("active");

    // document.getElementById(id).classList.add("active");

    //   document.getElementsByClassName("cus_heading").classList.remove("changed");

    // document.getElementsByClassName("cus_heading").classList.add("changed");

    // updateState({
    //   navigate_state: defaultState.navigate_state,
    //   themes: [
    //     {
    //       name: "Light",
    //       effect: "",
    //       dataColor: val,
    //       textColor: "Black",
    //     },
    //   ],
    //   toggleNav: defaultState.toggleNav
    // });
    // cookies.set("themecolor", val, { path: "/" })

  };

  let toggleNav = false;

  const toggleNavigation = () => {

    toggleNav = !toggleNav;
    // if (defaultState.toggleNav === "false") {
    //   updateState({
    //     navigate_state: defaultState.navigate_state,
    //     themes: defaultState.themes,
    //     toggleNav: "true",
    //   });
    // } else {
    //   updateState({
    //     navigate_state: defaultState.navigate_state,
    //     themes: defaultState.themes,
    //     toggleNav: "false",
    //   });
    // }

    // alert(defaultState.toggleNav);
  };

  const switchthemeHandler = () => {
    // alert(defaultState.themes[0].name);
    // if (defaultState.themes[0].name === "Light") {


    // document.documentElement.setAttribute("theme-data", "Light");
    // updateState({
    //   navigate_state: defaultState.navigate_state,
    //   themes: [
    //     {
    //       name: "Dark",
    //       effect: "dark",
    //       dataColor: cookies.get("themecolor"),
    //       // icon: <i className="fal fa-moon"></i>,
    //       textColor: "White",
    //     },
    //   ],
    //   toggleNav: defaultState.toggleNav,
    // });
    //  } else {
    // document.documentElement.setAttribute("theme-data", "Dark");
    // updateState({
    //   navigate_state: defaultState.navigate_state,
    //   themes: [
    //     {
    //       name: "Light",
    //       effect: "",
    //       dataColor: cookies.get("themecolor"),
    //       // icon: <i className="fad fa-sun"></i>,
    //       textColor: "Black",
    //     },
    //   ],
    //   toggleNav: defaultState.toggleNav,
    // });
    //  }

    // cookies.set("themename", defaultState.themes[0].name, { path: "/" });
    // cookies.set("themeeffect", defaultState.themes[0].effect, { path: "/" });
    // cookies.set("themecolor", defaultState.themes[0].dataColor, { path: "/" });
    // // cookies.set("themeicon", defaultState.themes[0].icon, { path: "/" });
    // cookies.set("themetext", defaultState.themes[0].textColor, { path: "/" });
  };

  // defaultState.themes[0].name !== "Light" ? document.documentElement.setAttribute("theme-data", "Light") : document.documentElement.setAttribute("theme-data", "Dark");

  return (
    <ErrorBoundary>
      <HashRouter>
        {/* <div>
        <SignIU />
      </div> */}


        {Authentication ? <Route
          path="/"
          exact
          render={() => (
            <Auth />
          )}
        /> :


          <div className="wrapper">
            <Sidebar
              dataColor={cookies.get("themecolor")}
              theme={cookies.get("themeeffect")}
              //  navigation_2={defaultState.navigate_state[0]}
              toggleNavigation={toggleNavigation}
            />
            <div className="main-panel" id="main-panel" menuvisibility={toggleNav.toString()}>
              <Nav toggleNavigation={toggleNavigation} />
              <Route
                path="/"
                exact
                render={() => (
                  <Layouts toggleNavigation={toggleNavigation} />
                )}
              />

              <Route
                path="/Profile"
                render={() => (
                  <Profile dataColor={cookies.get("themecolor")} theme={cookies.get("themeeffect")} />
                )}
              />

              <Route
                path="/Settings"
                exact
                render={() => (
                  <Setting
                    dataColor={cookies.get("themecolor")}
                    theme={cookies.get("themeeffect")}
                    textColor={cookies.get("themetext")}
                    sidebar={sidebarcolorHandler}
                    themeupdate={switchthemeHandler}
                    themename={cookies.get("themename")}
                  />
                )}
              />
              <Route path="/Reports" exact render={() => (<Reports />)} />
              <Route
                path="/ManageDevices"
                exact
                render={() => (
                  <ManageDevices
                    dataColor={cookies.get("themeeffect")}
                  />
                )}
              />
              {/* <Route path="/SignUI" exact render={() => <SignIU />} /> */}
            </div>
          </div>}
      </HashRouter>
    </ErrorBoundary>
  );
}


const mapStateToProps = (state) => {
  return {
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
