import React, { Component } from "react";
import "./BackupCard.scss";
// import Button from "react-bootstrap/Button";

import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
// import { RadioGroup, RadioButton } from "react-radio-buttons";
// import axios from "axios";
// import { object } from "@amcharts/amcharts4/core";
import MessageBox from "../../assets/MessageBox/MessageBox";
import * as actionTypes from "../../store/actions/actionTypes"
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
// import SubNavBar from "../../Components/SubNavBar/SubNavBar"




class Backupcard extends Component {

  static defaultProps = {
    numberOfMonths: 2
  };

  state = { ids: [], alias: [], selectedId: "" }

  token = localStorage.getItem('token');

  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }

  componentDidMount() {
    const devices = JSON.parse(localStorage.getItem('devices'));
    this.ids = devices.devices.filter(item => item.serviceDetails.status === 'active').map(item => { return { "alias": item.deviceDetails.alias, "id": item.deviceDetails.device_id } });
    // console.log(this.ids);
    this.setState({ ids: this.ids });
    this.setState({ alias: this.alias });
  }


  stopReRun;
  // deviceIdSelect = () => {
  //   let _id = document.getElementById("deviceIdSelect__reports");
  //   alert(_id.value)
  //   this.setState({ selectedId: _id.value }, () => { this.cookies.set("selectedId", this.state.selectedId, { path: "/" }); });
  // };

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
      bck_btn: "disabled",
      selectedFileType: "csv",
      fileType: [
        { csv: "fileType current" },
        { pdf: "fileType" },
        { xls: "fileType" }
      ],
      backup_data: {},
      msgBox: {
        _class: "msg_container animated zoomOutRight",
        isavailble: "false",
        header: "",
        body: ""
      }
    };
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
    this.setState({ bck_btn: "enabled" });
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }


  showMessage(msg) {
    this.setState({
      msgBox: {
        isavailble: "true",
        header: "Info!",
        body: msg,
        _class: "msg_container animated zoomInRight"
      }
    });
    const reset = () => {
      this.setState({
        msgBox: {
          isavailble: "true",
          header: "Info!",
          body: this.state.msgBox.body,
          _class: "msg_container animated zoomOutRight"
        }
      });
    };
    setTimeout(reset, 4000);
  }



  render() {
    //var data = [];

    if (this.props.error && this.props.error.includes("token")) {
      window.location.href = "/";
      window.location.reload();
    }

    const getBuckup = (method) => {
      let _to = new Date(this.state.to);
      const ye = new Intl.DateTimeFormat("en", {
        year: "numeric"
      }).format(_to);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
        _to
      );
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(_to);
      _to = ye + "-" + mo + "-" + da;

      let _from = new Date(this.state.from);
      const ye1 = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
        _from
      );
      const mo1 = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
        _from
      );
      const da1 = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
        _from
      );
      _from = ye1 + "-" + mo1 + "-" + da1;
      let _id = document.getElementById("deviceIdSelect__reports");
      let _freq = document.getElementById("freqSelect__reports");

      if (method === actionTypes.REPORT_DOWNLOAD) {
        this.props.onGenerateReport(_id.value, _from, _to, _freq.value, actionTypes.REPORT_DOWNLOAD, this.state.selectedFileType);
      }
      else if (method === actionTypes.REPORT_VIEW) {
        this.props.onGenerateReport(_id.value, _from, _to, _freq.value, actionTypes.REPORT_VIEW, null);
      }


      //console.log(this.state.backup_data);
      // data = this.state.backup_data;
      // console.log(data);
      // var csv = "Temperature,Humidity\n";
      // data.forEach(function(row) {
      //   csv += row.join(",");
      //   csv += "\n";
      // });

      //console.log(csv);
      // var hiddenElement = document.createElement("a");
      // hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      // hiddenElement.target = "_blank";
      // hiddenElement.download = "Data.csv";
      // hiddenElement.click();

    };

    const filetypehandler = val => {

      document.getElementById("csv_icon").classList.remove('fas');
      document.getElementById("csv_icon").classList.add('fal');
      document.getElementById("pdf_icon").classList.remove('fas');
      document.getElementById("pdf_icon").classList.add('fal');
      // document.getElementById("xls_icon").classList.remove('fas');
      // document.getElementById("xls_icon").classList.add('fal');
      // console.log(val);
      if (val === "csv") {

        document.getElementById("csv_icon").classList.remove('fal');
        document.getElementById("csv_icon").classList.add('fas');
        this.setState({
          selectedFileType: "csv",
          fileType: [
            { csv: "fileType current" },
            {
              pdf: "fileType"
            },
            { xls: "fileType" }
          ]
        });
      } else if (val === "pdf") {
        document.getElementById("pdf_icon").classList.remove('fal');
        document.getElementById("pdf_icon").classList.add('fas');
        this.setState({
          selectedFileType: "pdf",
          fileType: [
            { csv: "fileType" },
            { pdf: "fileType current" },
            { xls: "fileType" }
          ]
        });
      }
      //  else if (val === "xls") {
      //   document.getElementById("xls_icon").classList.remove('fal');
      //   document.getElementById("xls_icon").classList.add('fas');
      //   this.setState({
      //     selectedFileType: "xls",
      //     fileType: [
      //       { csv: "fileType" },
      //       { pdf: "fileType" },
      //       { xls: "fileType current" }
      //     ]
      //   });
      // }
      console.log(this.state);
    };

    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <div className="b_Content">
        {/* {console.log(this.state)} */}
        <div className="b_header">
          <h4>Backup Data</h4>
        </div>
        <div className="select-wrapper">

          <div className="deviceIdSelect__reports">
            <select
              id="deviceIdSelect__reports"
              onChange={this.deviceIdSelect}
            >
              {this.state.ids && this.state.ids.map(item => {
                if (item.id)
                  return (<option value={item.id} key={`id_${item.id}`}> {item.alias ? `${item.alias} (${item.id})` : `${item.id}`}</option>)
                else
                  return (<option value={item} key={`id_${item}`}>{item}</option>)

              })}
            </select>
          </div>
          &nbsp; &nbsp; &nbsp;
          <div className="deviceIdSelect__reports">
            <select
              id="freqSelect__reports">
              <option value="default" >Default</option>
              <option value="daily" >Daily</option>
              <option value="monthly" >Monthly</option>
              <option value="yearly" >Yearly</option>
            </select>
          </div>
        </div>
        <br />
        <div className="RangeExample">
          <p className="text_highlight">
            {!from && !to && "Please select the First Day."}
            {from && !to && "Please select the Last Day."}
            {from &&
              to &&
              `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}
          </p>
          {from && to && (
            <p className="resetbtn" onClick={this.handleResetClick}>
              {" "}
              <i className=" fal fa-sync"></i> <span>Reset</span>{" "}
            </p>
          )}
          <br />
          <DayPicker
            className="Selectable"
            numberOfMonths={this.props.numberOfMonths}
            selectedDays={[from, { from, to }]}
            modifiers={modifiers}
            onDayClick={this.handleDayClick}
          />
        </div>
        <br />
        <div className="b_footer">
          <div className="radio_con">
            <div
              className={this.state.fileType[0].csv}
              onClick={filetypehandler.bind(this, "csv")}
            >
              <i style={{ fontSize: "2rem", color: "#7B83EB" }} id="csv_icon" className="fas fa-file-csv"></i>
            </div>
            <div
              className={this.state.fileType[1].pdf}
              onClick={filetypehandler.bind(this, "pdf")}
            >
              <i style={{ fontSize: "2rem", color: "#F95248" }} id="pdf_icon" className="fal fa-file-pdf"></i>
            </div>
            {/* <div
              className={this.state.fileType[2].xls}
              onClick={filetypehandler.bind(this, "xls")}
            >
              <i style={{ fontSize: "2rem", color: "#21bc96" }} id="xls_icon" className="fal fa-file-spreadsheet"></i>
            </div> */}
            <div className="slider"></div>
          </div>{" "}
          <div className="btn_con purple">
            {from && to && <button onClick={() => getBuckup(actionTypes.REPORT_VIEW)}><i className="fad fa-eye"></i></button>}
          </div>
          <div className="btn_con">
            {from && to && <button onClick={() => getBuckup(actionTypes.REPORT_DOWNLOAD)}><i className="fad fa-download"></i></button>}
          </div>
        </div>
        <MessageBox
          headerText={this.state.msgBox.header}
          bodyText={this.state.msgBox.body}
          _type="info"
          _class={this.state.msgBox._class}
        />
      </div >
    );
  }
}


const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    reportData: state.reports.reportData,
    method: state.reports.method
  }
}

const mapDispatchToProps = dispatch => {
  return { onGenerateReport: (id, from, to, freq, method, fileType) => dispatch(actions.generateReport(id, from, to, freq, method, fileType)) }
}

export default connect(mapStateToProps, mapDispatchToProps)(Backupcard);
