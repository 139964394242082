import React, { Component } from "react";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    componentDidMount() {
        this.iframeRemover = setInterval(() => {
            document.querySelectorAll("iframe").forEach((iframe) => {
                if (!iframe.src.includes(window.location.hostname)) {
                    iframe.remove();
                }
            });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.iframeRemover);
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, info) {
        console.error("ErrorBoundary caught an error:", error, info);
    }

    logoutHandler = () => {
        console.log("logout")
        localStorage.removeItem("token");
        window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            console.log("Error Object:", this.state.error);
            return (
                <div style={styles.container}>
                    <div style={styles.card}>
                        <h1 style={styles.title}>Oops! Something went wrong.</h1>
                        <p style={styles.message}>
                            We're experiencing an unexpected issue. Please try refreshing the
                            page or come back later.
                        </p>
                        <div style={styles.buttonContainer}>
                            <button onClick={this.logoutHandler} style={styles.buttonOutline}>
                                Logout
                            </button>
                            <button
                                onClick={() => window.location.reload()}
                                style={styles.button}
                            >
                                Refresh Page
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

const styles = {
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "rgba(128, 128, 128, 0.05)",
        zIndex: "9999999999999999999"
    },
    card: {
        backgroundColor: "#fff",
        padding: "2rem",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
    },
    title: {
        fontSize: "2rem",
        fontWeight: "bold",
        color: "red",
        marginBottom: "1rem",
    },
    message: {
        color: "#666",
        marginBottom: "1.5rem",
    },
    buttonContainer: {
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonOutline: {
        padding: "10px 20px",
        border: "2px solid #007bff",
        backgroundColor: "transparent",
        color: "#007bff",
        fontWeight: "bold",
        borderRadius: "5px",
        cursor: "pointer",
    },
    button: {
        padding: "10px 20px",
        backgroundColor: "#007bff",
        color: "#fff",
        fontWeight: "bold",
        borderRadius: "5px",
        cursor: "pointer",
        border: "none",
    },
};

export default ErrorBoundary;
